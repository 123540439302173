@import "./_theme_colors";
@import "./_theme_font";
@import "./_theme_custom";
@import "./_dates_picker";
//
@import "./header";

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";


:root {
    --react-international-phone-flag-width: 30px;
    --react-international-phone-flag-height: 30px;
    --react-international-phone-height: 44px;
    --react-international-phone-border-radius: 1rem;
    --react-international-phone-country-selector-arrow-size: 6px;
}

.react-international-phone-country-selector-button__flag-emoji {
    margin: 0px 10px !important;
}

