@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer utilities {
    .phone-dark {
        --react-international-phone-background-color: rgba(var(--c-neutral-900), var(--tw-bg-opacity));
        --react-international-phone-text-color: white;
        --react-international-phone-border-color: rgba(var(--c-neutral-700), var(--tw-border-opacity));
        --react-international-phone-disabled-background-color: whitesmoke;
        --react-international-phone-disabled-text-color: #666;
        --react-international-phone-country-selector-background-color-hover: rgba(var(--c-neutral-700));
        --react-international-phone-disabled-country-selector-background-color: rgba(var(--c-neutral-600));
        --react-international-phone-country-selector-arrow-color: white;
        --react-international-phone-selected-dropdown-item-background-color: rgba(var(--c-neutral-700));
    }
}

@layer base {
    [type="text"],
    [type="email"],
    [type="url"],
    [type="password"],
    [type="number"],
    [type="date"],
    [type="datetime-local"],
    [type="month"],
    [type="search"],
    [type="tel"],
    [type="time"],
    [type="week"],
    [multiple],
    textarea,
    select {
        @apply bg-white dark:bg-neutral-900 border-neutral-200 dark:border-neutral-700 rounded-full focus:ring-indigo-500
        focus:border-indigo-500 block w-full sm:text-sm text-neutral-900 dark:text-neutral-200;
    }
}
